import React, { useContext, useEffect, useState } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const InstallPage: React.FC = () => {
  const { language } = useContext(I18nextContext);
  // const [deferredPrompt, setDeferredPrompt] = useState(null)
  //
  // useEffect(() => {
  //   window.addEventListener('beforeinstallprompt', (e) => {
  //     // Prevent Chrome 67 and earlier from automatically showing the prompt
  //     e.preventDefault()
  //     // Save the event so it can be triggered later.
  //     setDeferredPrompt(e)
  //     console.log('e', e);
  //     // Update UI to notify the user they can add to home screen
  //     // setShowA2HS(true)
  //   })
  // }, [])
  return (
    <Layout page='install' locale={language}>
        <SEO title="Install Our App"/>
    </Layout>
  );
};

export default InstallPage;
